/**
 * Specifies an envMap on an entity, without replacing any existing material
 * properties.
 */
AFRAME.registerComponent("light-map-geometry", {
    schema: {
        lightMap: {type: "map"},
        format: {default: "RGBFormat"},
    },

    init: function () {
        this.el.addEventListener("object3dset", this.applyLightMap.bind(this));
    },

    update: function (oldData) {
        this.lightMapTexture = new THREE.Texture(this.data.lightMap);
        this.lightMapTexture.needsUpdate = true;

        this.applyLightMap();
    },

    applyLightMap: function () {
        const mesh = this.el.getObject3D("mesh");

        if (!mesh) return;

        mesh.traverse((node) => {
            if (node.geometry && node.geometry.type == "BufferGeometry") {
                //console.log(node);
                //console.log(node.geometry.attributes);
                node.geometry.attributes.uv2 = node.geometry.attributes.uv.clone();
            }
            if (node.material && "lightMap" in node.material) {
                node.material.lightMap = this.lightMapTexture;
                node.material.needsUpdate = true;
            }
        });
    },
});
